import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

const CheckboxField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => {
      let convertedValues = prevState[name];
      if (typeof convertedValues === 'string') {
        convertedValues = convertedValues.split(',');
      }

      const selectedValues = convertedValues || [];
      if (value === 'None' && checked) {
        return {
          ...prevState,
          [name]: ['None'],
        };
      } else if (checked) {
        const updatedValues = selectedValues.includes('None') ? selectedValues.filter((val) => val !== 'None') : selectedValues;
        return {
          ...prevState,
          [name]: [...updatedValues, value],
        };
      } else {
        return {
          ...prevState,
          [name]: selectedValues.filter((val) => val !== value),
        };
      }
    });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <FormControl fullWidth error={!!errors[field.name]} sx={{ maxWidth: 600, textAlign: 'left' }}>
        <FormLabel id="checkbox-group-label" sx={{ marginBottom: '10px', color: 'black' }}>
          {field.label}
          {field.required && <span style={{ color: 'red', whiteSpace: 'nowrap' }}> *</span>}
        </FormLabel>
        <FormGroup
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)',
            },
            gap: 1,
          }}
        >
          {field.options.map((o) => (
            <FormControlLabel
              key={o.value}
              inputRef={refs.current[field.name]}
              sx={{
                '& .MuiFormControlLabel-label': { fontSize: '14px' },
              }}
              control={
                <Checkbox
                  value={o.value}
                  name={field.name}
                  onChange={handleCheckboxChange}
                  checked={formData[field.name]?.includes(o.value) || false}
                />
              }
              label={o.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CheckboxField;

import { Box, Button, CircularProgress, Input } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import styles from './fields.module.css';

const UploadFileField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const [previewImg, setPreviewImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const fieldValue = formData[field.name];

  const getDynamicRequired = (val) => {
    return typeof val === 'object' ? val.value.includes(formData[val.name]) : val;
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0] || null;
    if (!file) {
      return;
    }

    const fileExt = file.name.split('.').pop();
    const newFile = new File([file], `${field.name}.${fileExt}`, { type: file.type });
    const previewImg = URL.createObjectURL(file);

    const img = new Image();
    img.onload = () => {
      if (img.width > img.height) {
        setPreviewImg(previewImg);
        setFormData((prevState) => ({
          ...prevState,
          [name]: newFile,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
      }
    };
    img.onerror = () => {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    };

    img.src = previewImg;
  };

  useEffect(() => {
    if (fieldValue) {
      if (fieldValue instanceof Blob) {
        setPreviewImg(URL.createObjectURL(fieldValue));
      } else {
        setLoading(true);
      }
    }
  }, [fieldValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      marginBottom={field.marginBottom}
    >
      <label htmlFor={field.name} className={styles.previewContainer}>
        {previewImg ? (
          <img src={previewImg} alt="Preview" className={styles.previewImage} />
        ) : loading ? (
          <CircularProgress color="inherit" size={40} />
        ) : (
          <PhotoCamera className={styles.photoIcon} />
        )}
      </label>
      <Input
        fullWidth
        type="file"
        sx={field.sx}
        id={field.name}
        key={field.name}
        name={field.name}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={refs.current[field.name]}
        inputProps={{ accept: 'image/*' }}
        required={getDynamicRequired(field.required)}
      />
      <div>
        <label htmlFor={field.name} className={styles.buttonLabel}>
          <Button variant="outlined" color="primary" component="span" fullWidth>
            {field.label}
            {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
          </Button>
        </label>
      </div>
      {!!errors[field.name] && <div className={styles.helperText}>{field.helperText}</div>}
    </Box>
  );
};

export default UploadFileField;
